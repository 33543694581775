<template>
  <div class="qrcode">
    <img :src="QRImgUrl" />
  </div>
</template>

<script>
import QRCode from 'qrcode'
export default {
  name: 'Qrcode',

  data() {
    return {
      QRImgUrl: '',
      QRlink: ''
    }
  },

  mounted() {
    // this.getQRcode()
  },

  methods: {
    getQRcode() {
      let opts = {
        errorCorrectionLevel: 'L', //容错级别
        type: 'image/png', //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 5, //二维码留白边距
        width: 170, //宽
        height: 170, //高
        text: 'http://www.xxx.com', //二维码内容
        color: {
          dark: '#666666', //前景色
          light: '#fff' //背景色
        }
      }
      //this.QRlink 生成的二维码地址url
      QRCode.toDataURL(this.QRlink, opts, (err, url) => {
        if (err) throw err
        //将生成的二维码路径复制给data的QRImgUrl
        this.QRImgUrl = url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.qrcode{
  display: flex;
  justify-content: center;
  padding: 20px;
}
</style>
